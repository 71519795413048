.error-common {
	position: relative;
	overflow: hidden;
	text-align: center;
	.guide-error {
		padding: 154px 0 198px 0;
		@include screen(custom, max, $max-sm) {
			padding: 80px 9px 73px 9px;
		}
	}
	.guide-title {
		margin-bottom: 17px;
		font-size: 40px;
		line-height: 48px;
		color: $color-black;
		@include font-family($font-semibold);
		@include screen(custom, max, $max-sm) {
			margin-bottom: 15px;
			font-size: 24px;
			line-height: 28px;
			br {display: none;}
		} 
	}
	.guide-subtext {
		font-size: 18px;
		line-height: 24px;
		color: $color-nightrider;
		@include screen(custom, max, $max-sm) {
			font-size: 16px;
			line-height: 24px;
			color: $color-dimgray;
		}
	}
	.icon {
		display: block;
		margin-bottom: 22px;
		@include screen(custom, max, $max-sm) {
			margin-bottom: 16px;
		}
		img {
			width: 80px;
			height: 80px;
			@include screen(custom, max, $max-sm) {
				width: 60px;
				height: 60px;
			}
		}
	}
	.extra-primary {
		color: $color-carmine;
		text-decoration: underline;
	}
}

.tabs-contents-wrapper {
	.error-common {
		.icon {
			img {
				width: 70px;
				height: 70px;
			}
		}

		.guide-title {
			font-size: 32px;
			line-height: 40px;
		}
		.guide-subtext {
			color: $color-dimgray;
			font-size: 16px;
			line-height: 24px;
		}
	}
}

.error-wrapper {
	.error-common {
		.guide-error {
			padding: 25px 0;
		}
		.icon {
			img {
				width: 60px;
				height: 60px;
			}
			margin-bottom: 16px;
		}
		.guide-title {
			font-size: 20px;
			line-height: 24px;
			color: #000;
			margin: 0 0 8px;
		}
		.guide-subtext {
			font-size: 14px;
			line-height: 20px;
			color: $color-dimgray;
			margin: 0;
		}
	}
	@include screen(custom, max, $max-sm) {
		display: none;
	}
}